var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DisplayContainer',{attrs:{"label":"SPOT-Prozesse"}},[(_vm.dataLoaded)?[_c('div',{staticClass:"spot-process-dashboard"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.reportHeaders,"items":_vm.reportData,"item-key":"processInstanceId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.dataLoading,"loading-text":"Daten werden geladen...","no-data-text":"Es sind keine Einträge vorhanden.","no-results-text":"Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden.","footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1]
        },"custom-filter":_vm.customFilter,"search":_vm.searchText},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"item",fn:function(row){return [_c('tr',[_c('td',{staticClass:"process-status"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(row.item.state === 'ACTIVE')?_c('div',_vm._g(_vm._b({staticClass:"status-green"},'div',attrs,false),on)):(row.item.state === 'INCIDENT')?_c('div',_vm._g(_vm._b({staticClass:"status-red"},'div',attrs,false),on)):(row.item.state === 'COMPLETED')?_c('div',_vm._g(_vm._b({staticClass:"status-grey"},'div',attrs,false),on)):(row.item.state === 'EXTERNALLY_TERMINATED')?_c('div',_vm._g(_vm._b({staticClass:"status-light-grey"},'div',attrs,false),on)):_c('div',_vm._g(_vm._b({staticClass:"status-unknown"},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getStateString(row.item.state)))])])],1),_c('td',{staticClass:"process-start"},[_c('div',[_vm._v(" "+_vm._s(new Date(row.item.dateProcessStart).toLocaleString( 'de-DE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' } ))+" ")])]),_c('td',[_vm._v(" "+_vm._s(row.item.userInitiator ? row.item.userInitiator : '-')+" ")]),_c('td',[_vm._v(" "+_vm._s(row.item.processDefinitionName)+" ")]),_c('td',{staticClass:"activity-user"},_vm._l((row.item.currentActivity),function(user){return _c('div',{key:user.activity,staticClass:"icon-wrapper"},[_c('div',{staticClass:"activity-col"},[(user.type === 'userTask')?_c('v-icon',{staticStyle:{"font-size":"20px","padding-left":"5px"}},[_vm._v(" mdi-account-details ")]):_vm._e(),_vm._v(" "+_vm._s(user.activity)+" ")],1),(user.user)?_c('div',{staticClass:"user-account"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"20px","padding-left":"5px"}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-outline ")])]}}],null,true)},[_c('span',[_c('div',[_vm._v("Bearbeiter: "+_vm._s(user.user))])])])],1):_vm._e()])}),0),_c('td',[_c('div',{staticClass:"icon-wrapper"},[_vm._v(" "+_vm._s(row.item.contractId)+" ")])])])]}}],null,false,3064161136),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]:[_c('div',[_c('span',[_vm._v(" Um Last auf den Server zu verringern wurde das automatische laden der Prozesse vorerst deaktiviert. ")]),_c('v-btn',{attrs:{"disabled":_vm.dataLoading,"loading":_vm.dataLoading},on:{"click":function($event){return _vm.fetchReport(_vm.customerId)}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Prozesse laden ")],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }