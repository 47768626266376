<template>
  <DisplayContainer label="SPOT-Prozesse">
    <template v-if="dataLoaded">
      <div class="spot-process-dashboard">
        <v-data-table
          v-model="selected"
          :headers="reportHeaders"
          :items="reportData"
          item-key="processInstanceId"
          class="elevation-1"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="dataLoading"
          loading-text="Daten werden geladen..."
          no-data-text="Es sind keine Einträge vorhanden."
          no-results-text="Für den eingegebenen Suchbegriff konnten keine Einträge gefunden werden."
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            'items-per-page-text': 'Zeilen pro Seite:',
            'items-per-page-options': [10, 25, 50, 100, -1]
          }"
          :custom-filter="customFilter"
          :search="searchText"
        >
          <template #top>
            <v-toolbar flat>
              <v-toolbar-title> </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchText"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
                clearable
              />
            </v-toolbar>
          </template>
          <template #item="row">
            <tr>
              <td class="process-status">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-if="row.item.state === 'ACTIVE'"
                      class="status-green"
                      v-bind="attrs"
                      v-on="on"
                    />
                    <div
                      v-else-if="row.item.state === 'INCIDENT'"
                      class="status-red"
                      v-bind="attrs"
                      v-on="on"
                    />
                    <div
                      v-else-if="row.item.state === 'COMPLETED'"
                      class="status-grey"
                      v-bind="attrs"
                      v-on="on"
                    />

                    <div
                      v-else-if="row.item.state === 'EXTERNALLY_TERMINATED'"
                      class="status-light-grey"
                      v-bind="attrs"
                      v-on="on"
                    />
                    <div
                      v-else
                      class="status-unknown"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>{{ getStateString(row.item.state) }}</span>
                </v-tooltip>
              </td>
              <td class="process-start">
                <div>
                  {{
                    new Date(row.item.dateProcessStart).toLocaleString(
                      'de-DE',
                      {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit'
                      }
                    )
                  }}
                </div>
              </td>
              <td>
                {{ row.item.userInitiator ? row.item.userInitiator : '-' }}
              </td>
              <td>
                {{ row.item.processDefinitionName }}
              </td>
              <td class="activity-user">
                <div
                  v-for="user in row.item.currentActivity"
                  :key="user.activity"
                  class="icon-wrapper"
                >
                  <div class="activity-col">
                    <v-icon v-if="user.type === 'userTask'" style="font-size: 20px; padding-left: 5px">
                      mdi-account-details
                    </v-icon>
                    {{ user.activity }}
                  </div>

                  <div
                    v-if="user.user"
                    class="user-account"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          style="font-size: 20px; padding-left: 5px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-account-outline
                        </v-icon>
                      </template>
                      <span>
                        <div>Bearbeiter: {{ user.user }}</div>
                      </span>
                    </v-tooltip>
                  </div>
                </div>
              </td>
              <td>
                <div class="icon-wrapper">
                  {{ row.item.contractId }}
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </template>
    <template v-else>
      <div>
        <span>
          Um Last auf den Server zu verringern wurde das automatische laden der Prozesse vorerst deaktiviert.
        </span>
        <v-btn
          :disabled="dataLoading"
          :loading="dataLoading"
          @click="fetchReport(customerId)"
        >
          <v-icon>mdi-download</v-icon>
          Prozesse laden
        </v-btn>
      </div>
    </template>
  </DisplayContainer>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import Loader from '@/components/elements/Loader.vue';
import PortingProcessDashboardPopup from '@/dashboard/PortingProcessDashboardPopup.vue';
import ProcessViewerWithHighlighting from '@/dashboard/ProcessViewerWithHighlighting.vue';
import WbciCancellation from '@/dashboard/WbciCancellation.vue';

import { HTTP } from '@/main/httpClient.js';

export default {
  name: 'DisplaySpotProcesses',
  components: {
    DisplayContainer,
    Loader,
    PortingProcessDashboardPopup,
    ProcessViewerWithHighlighting,
    WbciCancellation
  },
  props: {
    customerId: { type: Number, required: true }
  },
  data: () => ({
    selected: [],
    sortBy: 'dateProcessStart',
    sortDesc: true,
    dataLoading: false,
    dataLoaded: false,
    // filterable: false does not mean that the filter will not search in this field, the filter function will work on the whole item.
    // This is just so vuetify will not call the filter function multiple times for each row.
    reportHeaders: [
      {
        text: 'Status',
        value: 'state'
      },
      {
        text: 'Startdatum',
        align: 'start',
        value: 'dateProcessStart',
        filterable: false
      },
      {
        text: 'Gestartet Von',
        value: 'userInitiator',
        filterable: false
      },
      {
        text: 'Prozesse Name',
        value: 'processDefinitionName',
        filterable: false
      },
      {
        text: 'Aktueller Prozessschritt',
        value: 'currentActivity',
        filterable: false
      },
      { text: 'Vertragsnummer', value: 'contractId', filterable: false }
    ],
    reportData: [],
    searchText: ''
  }),
  computed: {
    isSupervisor() {
      return this.$auth.check(['LgSpotPortierungLeitung', 'camunda-admin']);
    }
  },
  mounted: function () {
    // this.fetchReport(this.customerId);
  },
  methods: {
    fetchReport: function (customerId) {
      this.dataLoading = true;
      HTTP.get(`/customer/process/${customerId}`)
        .then((result) => {
          this.reportData = this.mapReportDataToTable(result.data);
          this.$emit('customerProcessesUpdated', {
            customerId,
            processes: this.reportData
          });
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Daten konnten nicht geladen werden.'
            )
          )
        )
        .finally(() => {
          this.dataLoaded = true;
          this.dataLoading = false;
        });
    },
    mapReportDataToTable: function (reportData) {
      return reportData.map((reportElement) => {
        const startDate = reportElement.dateProcessStart;
        return {
          currentActivity: reportElement.currentActivities?.map((ac) => {
            return ac;
          }),
          state: reportElement.state,
          userInitiator: reportElement.userInitiator,
          dateProcessStart: startDate ? new Date(startDate) : null,
          processInstanceId: reportElement.processInstanceId,
          contractId: reportElement.processInfo
            ? reportElement.processInfo.contractId
            : '',
          processDefinitionName: reportElement.processDefinitionName
        };
      });
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    getStateString(state) {
      switch (state) {
        case 'ACTIVE':
          return 'Prozess aktiv';
        case 'INCIDENT':
          return 'Fehler aufgetreten';
        case 'COMPLETED':
          return 'Prozess beendet';
        case 'EXTERNALLY_TERMINATED':
          return 'Prozess abgebrochen';
        default:
          return 'Unbekannter Zustand';
      }
    },
    customFilter: function (_, search, item) {
      if (search === null || search === '') {
        return true;
      }
      const activityNames = item.currentActivity.map((activity) =>
        activity.activity.toLowerCase()
      );
      const itemString = [
        this.getStateString(item.state).toLowerCase(),
        item.userInitiator.toLowerCase(),
        item.contractId
      ]
        .concat(activityNames)
        .concat(item.numbers)
        .join(' | ');
      return itemString.includes(search.toLowerCase());
    }
  }
};
</script>

<style scoped lang="scss">
.spot-process-dashboard::v-deep .process-start {
  width: 150px;
}
.spot-process-dashboard::v-deep td.process-status {
  width: 85px;
}
.spot-process-dashboard::v-deep .activity-user {
  max-width: 350px;
}
.spot-process-dashboard::v-deep .status-unknown {
  width: 30px;
  height: 30px;
  background-color: #6d005e;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #6d005e;
}
.spot-process-dashboard::v-deep .status-green {
  width: 30px;
  height: 30px;
  background-color: #80ed99;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #80ed99;
}
.spot-process-dashboard::v-deep .status-grey {
  width: 30px;
  height: 30px;
  background-color: var(--goe-fontColor-lighter);
  border-radius: 50%;
  box-shadow: 0 0 4px 1px var(--goe-fontColor-lighter);
}
div.spot-process-dashboard::v-deep .status-red {
  width: 30px;
  height: 30px;
  background-color: #ff6b6b;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #ff6b6b;
}
div.spot-process-dashboard::v-deep .status-light-grey {
  width: 30px;
  height: 30px;
  background-color: #dddd;
  border-radius: 50%;
  box-shadow: 0 0 4px 1px #dddd;
}
div.spot-process-dashboard::v-deep .icon-wrapper {
  display: flex;
  .activity-col {
    flex-direction: column;
  }
}
.v-tooltip__content {
  pointer-events: auto;
}
</style>
